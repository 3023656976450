

























































































































































































import isBlank from 'is-blank'
import Vue from 'vue'
import CurrencyInput from '../shared/CurrencyInput.vue'
import Countries from '../../assets/countries.json'
import {
  AndFilterGroup,
  Filter,
  FilterMapping,
  FilterMappings,
} from '../../lib/reports/types'
import { getElasticSearchClient } from '@/services/elasticSearch'
import { CurrencyEnum } from '../../../../shared/types/types'

export default Vue.extend({
  name: 'ManageFilters',
  components: {
    CurrencyInput,
  },
  props: {
    initFilters: {
      type: Array,
      default: () => [] as AndFilterGroup[],
    },
  },
  data() {
    return {
      autocompleteData: [] as string[],
      andGroups: [] as AndFilterGroup[],
    }
  },
  computed: {
    filterMappings(): FilterMappings {
      return {
        ordersCancelled: {
          name: 'Cancelled',
          operators: ['is', 'is not'],
          input: 'checkbox',
        },
        ordersPaymentStatus: {
          name: 'Payment Status',
          operators: ['is', 'is not'],
          input: 'select',
          options: [
            { label: 'Paid', value: 'paid' },
            { label: 'Partially Refunded', value: 'partially_refunded' },
            { label: 'Processing', value: 'processing' },
            { label: 'Requires Action', value: 'requires_action' },
            { label: 'Refunded', value: 'refunded' },
            { label: 'Unpaid', value: 'unpaid' },
          ],
        },
        ordersFulfillmentStatus: {
          name: 'Fulfillment Status',
          operators: ['is', 'is not'],
          input: 'select',
          options: [
            { label: 'Fulfilled', value: 'fulfilled' },
            { label: 'Partial', value: 'partial' },
            { label: 'Unfulfilled', value: 'unfulfilled' },
          ],
        },
        ordersOrderId: {
          name: 'Order ID',
          operators: ['is', 'is not'],
          input: 'text',
        },
        ordersDiscountName: {
          name: 'Discount Name',
          operators: ['is', 'is not'],
          input: 'tags',
          vBind: {
            autocomplete: true,
            data: this.autocompleteData,
            field: 'discount.name',
            'allow-new': true,
          },
          vOn: {
            typing: async (query: string) => {
              const elasticSearch = getElasticSearchClient()
              const { data } = await elasticSearch.post('/orders/_search', {
                aggs: {
                  discountNames: {
                    terms: {
                      field: 'discount.name',
                      order: {
                        _count: 'desc',
                      },
                      size: 10000,
                      shard_size: 25,
                    },
                  },
                },
                size: 0,
              })
              const codes = data.aggregations.discountNames.buckets.map(
                (b: { key: string }) => b.key
              )
              this.autocompleteData = this.searchCodes(codes, query)
            },
          },
        },
        ordersAccountIds: {
          name: 'Payment Account IDs (comma seperated)',
          operators: ['is', 'is not'],
          input: 'tags',
          vBind: {
            // autocomplete: true,
            // data: this.autocompleteData,
            field: 'charge.paymentProvider.accountId',
            'allow-new': true,
          },
          // vOn: {
          //   typing: async () => {
          //     const elasticSearch = getElasticSearchClient()
          //     const { data } = await elasticSearch.post('/orders/_search', {
          //       aggs: {
          //         accounIds: {
          //           terms: {
          //             field: 'charge.paymentProvider.accountId',
          //             order: {
          //               _count: 'desc',
          //             },
          //             size: 1000,
          //           },
          //         },
          //       },
          //       size: 0,
          //     })

          //     this.autocompleteData = data.aggregations.accounIds.buckets.map(
          //       (b: { key: string }) => b.key
          //     )
          //   },
          // },
        },
        ordersCurrency: {
          name: 'Orders Currency',
          operators: ['is', 'is not'],
          input: 'select',
          vBind: {
            field: 'totalPrice.currency',
          },
          options: Object.keys(CurrencyEnum).map((currency) => ({
            label: currency.toUpperCase(),
            value: currency.toUpperCase(),
          })),
        },
        billingsBillingCompany: {
          name: 'Billing Company',
          operators: ['is', 'is not'],
          input: 'text',
        },
        billingsBillingCity: {
          name: 'Billing City',
          operators: ['is', 'is not'],
          input: 'text',
        },
        billingsBillingRegion: {
          name: 'Billing Region',
          operators: ['is', 'is not'],
          input: 'text',
        },
        billingsBillingCountryRegion: {
          name: 'Billing Country/Region',
          operators: ['is', 'is not'],
          input: 'select',
          options: Countries.data.map((country) => ({
            label: country.name,
            value: country.code,
          })),
        },
        billingsBillingPostalCode: {
          name: 'Billing Postal Code',
          operators: ['is', 'is not'],
          input: 'text',
        },
        customersCustomerEmail: {
          name: 'Customer Email',
          operators: ['is', 'is not'],
          input: 'tags',
        },
        customersCustomerId: {
          name: 'Customer ID',
          operators: ['is', 'is not'],
          input: 'tags',
        },
        customersCustomerName: {
          name: 'Customer Name',
          operators: ['is', 'is not'],
          input: 'text',
        },
        productsProductId: {
          name: 'Product ID',
          operators: ['is', 'is not'],
          input: 'tags',
        },
        productsPrice: {
          name: 'Price',
          operators: [
            'is',
            'is not',
            'greater than',
            'less than',
            'greater or equal',
            'less or equal',
          ],
          input: 'price',
        },
        productsProductTitle: {
          name: 'Product Title',
          operators: ['is', 'is not'],
          input: 'tags',
          vBind: {
            autocomplete: true,
            data: this.autocompleteData,
            field: 'title',
            'allow-new': true,
          },
          vOn: {
            typing: async (query: string) => {
              if (query.length < 3) {
                return
              }
              const elasticSearch = getElasticSearchClient()
              const { data } = await elasticSearch.post('/orders/_search', {
                query: {
                  nested: {
                    path: 'lineItems',
                    query: {
                      bool: {
                        should: [
                          {
                            match_phrase: {
                              'lineItems.productTitle': query,
                            },
                          },
                        ],
                        minimum_should_match: 1,
                      },
                    },
                    score_mode: 'none',
                  },
                },
              })
              const titles = []
              for (const hit of data.hits.hits) {
                for (const lineItems of hit._source.lineItems) {
                  titles.push(lineItems.productTitle)
                }
              }
              // dedup and sort
              this.autocompleteData = Array.from(new Set(titles)).sort()
            },
          },
        },
        productsVariantId: {
          name: 'Variant ID',
          operators: ['is', 'is not'],
          input: 'tags',
          vBind: {
            autocomplete: false,
            field: 'variantId',
            'allow-new': true,
          },
        },
        productsVariantSku: {
          name: 'Variant SKU',
          operators: ['is', 'is not'],
          input: 'tags',
          vBind: {
            field: 'sku',
            'allow-new': true,
          },
        },
        productsVariantTitle: {
          name: 'Variant Title',
          operators: ['is', 'is not'],
          input: 'tags',
          vBind: {
            autocomplete: true,
            data: this.autocompleteData,
            field: 'title',
            'allow-new': true,
          },
          vOn: {
            typing: async (query: string) => {
              const elasticSearch = getElasticSearchClient()
              const { data } = await elasticSearch.post('/orders/_search', {
                query: {
                  nested: {
                    path: 'lineItems',
                    query: {
                      bool: {
                        should: [
                          {
                            match_phrase: {
                              'lineItems.variantTitle': query,
                            },
                          },
                        ],
                        minimum_should_match: 1,
                      },
                    },
                    score_mode: 'none',
                  },
                },
              })
              const titles = []
              for (const hit of data.hits.hits) {
                for (const lineItems of hit._source.lineItems) {
                  titles.push(lineItems.variantTitle)
                }
              }
              // dedup and sort
              this.autocompleteData = Array.from(new Set(titles)).sort()
            },
          },
        },
        shippingsShippingCity: {
          name: 'Shipping City',
          operators: ['is', 'is not'],
          input: 'tags',
        },
        shippingsShippingRegion: {
          name: 'Shipping Region',
          operators: ['is', 'is not'],
          input: 'tags',
        },
        shippingsShippingCountryRegion: {
          name: 'Shipping Country/Region',
          operators: ['is', 'is not'],
          input: 'select',
          options: Countries.data.map((country) => ({
            label: country.name,
            value: country.code,
          })),
        },
        shippingsShippingPostalCode: {
          name: 'Shipping Postal Code',
          operators: ['is', 'is not'],
          input: 'text',
        },
      }
    },
    isValid(): boolean {
      // If everything has been removed. let it be applied.
      if (
        this.andGroups.length === 1 &&
        this.andGroups[0].orGroups.length === 1 &&
        isBlank(this.andGroups[0].orGroups[0].values)
      ) {
        return true
      }

      // If there are gropus, all of the values have to be set.
      for (const andGroup of this.andGroups) {
        for (const orGroup of andGroup.orGroups) {
          if (isBlank(orGroup.values)) {
            return false
          }
        }
      }

      // Seems to be valid.
      return true
    },
  },
  created() {
    if (isBlank(this.initFilters)) {
      this.addAndGroup()
    } else {
      this.andGroups = this.initFilters as AndFilterGroup[]
    }
  },
  methods: {
    currentFilterMapping(filter: Filter): FilterMapping | false | undefined {
      return filter.filter.length > 0 && this.filterMappings[filter.filter]
    },
    addAndGroup() {
      const newOrGroup: AndFilterGroup = {
        orGroups: [],
      }
      this.andGroups.push(newOrGroup)
      this.addOrGroup(newOrGroup)
    },
    removeAndGroup(group: AndFilterGroup) {
      this.andGroups.splice(this.andGroups.indexOf(group), 1)
    },
    addOrGroup(andGroup: AndFilterGroup) {
      const newOrGroup: Filter = {
        filter: '',
        operator: 'is',
        values: [],
      }
      andGroup.orGroups.push(newOrGroup)
    },
    removeOrGroup(andGroup: AndFilterGroup, orGroup: Filter) {
      if (
        this.andGroups.length === 1 &&
        this.andGroups[0].orGroups.length === 1
      ) {
        // If there is only one group left, empty it
        orGroup.filter = ''
        orGroup.values = []
      } else if (this.andGroups.length > 1 && andGroup.orGroups.length === 1) {
        // If there are more and groups, but there is only one orGroup inside then remove the andGroup
        this.removeAndGroup(andGroup)
      } else {
        andGroup.orGroups.splice(andGroup.orGroups.indexOf(orGroup), 1)
      }
    },
    updateFilter(filter: Filter, value: string): void {
      filter.filter = value
      filter.values = []
    },
    searchCodes(codes: string[], searchTerm: string): string[] {
      const results: string[] = []

      for (const code of codes) {
        if (code.toLowerCase().includes(searchTerm.toLowerCase())) {
          results.push(code)
        }
      }

      return results
    },
    submit(): void {
      this.$emit('value', this.andGroups)
      this.$emit('close')
    },
  },
})
