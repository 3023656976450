

























import Vue from 'vue'

export default Vue.extend({
  name: 'Metric',
  props: {
    headline: {
      required: true,
      type: String,
    },
    headlineUrl: {
      type: String,
      default: '',
    },
    pill: {
      type: String,
      default: '',
    },
    value: {
      required: true,
      type: [String, Number],
    },
    unit: {
      required: true,
      type: String,
    },
  },
  computed: {
    hasPill(): boolean {
      return this.pill.length > 0
    },
  },
})
