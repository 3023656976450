









import Vue from 'vue'

export default Vue.extend({
  name: 'GroupByIntervalSelect',
  props: {
    value: {
      type: String,
      required: true,
    },
  },
  methods: {
    onInput(value: string): void {
      this.$emit('input', value)
    },
  },
})
