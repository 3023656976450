import startcase from 'startcase'
import { CurrencyEnum } from '../../../../shared/types/types'
import { formatPrice } from '../utils'
import { AndFilterGroup, DatePickerData } from './types'
import { mapFilterToElasticQuery } from './mapFilterToElasticQuery'

export const filterStartCase = (value: string): string => {
  const words = startcase(value)
  return words.substring(words.indexOf(' ') + 1)
}

export const filterValueLabel = (value: string | boolean | number): string => {
  switch (typeof value) {
    case 'string':
      return startcase(value)
    case 'boolean':
      return value ? 'Yes' : 'No'
    case 'number':
      return value.toString()
    default:
      return value
  }
}

export const filterPriceLabel = (value: number): string => {
  return formatPrice({ amount: value, currency: CurrencyEnum.Usd })
}

export const elasticSearchFilterQuery = (
  dataFilters: AndFilterGroup[],
  datePicker: DatePickerData,
  refunds = false,
  usdOnly = false
): Record<string, any> => {
  return {
    bool: {
      must: [
        ...dataFilters.map(
          (andGroup: AndFilterGroup): Record<string, any> => {
            const orFilters: Record<string, any> = {
              bool: {
                minimum_should_match: 1,
                should: [],
              },
            }
            orFilters.bool.should = andGroup.orGroups
              .filter(
                (orGroup) =>
                  orGroup.filter !== '' && orGroup.operator !== 'is not'
              )
              .map(mapFilterToElasticQuery)
            orFilters.bool.must_not = andGroup.orGroups
              .filter(
                (orGroup) =>
                  orGroup.filter !== '' && orGroup.operator === 'is not'
              )
              .map(mapFilterToElasticQuery)
            return orFilters
          }
        ),
      ],
      filter: [
        ...(refunds ? [{ term: { paymentStatus: 'refunded' } }] : []),
        ...(usdOnly
          ? [
              {
                term: {
                  'totalPrice.currency': 'USD',
                },
              },
            ]
          : []),
        {
          range: {
            '@timestamp': {
              format: 'strict_date_optional_time',
              // gte: '2024-06-28T05:00:00.000-12:00',
              // lte: '2024-07-06T04:59:59.999+12:00',
              gte: datePicker.startDate,
              lte: datePicker.endDate,
              // time_zone: 'Asia/Tokgccyo',
              // time_zone: 'Asia/Tokyo',
            },
          },
        },
      ],
    },
  }
}

export const totalSalesBackgroundOpacity = <T>(
  rows: T[],
  totalSalesSalesFunction: (row: T) => number
): Record<number, number> => {
  const max = rows.reduce((acc: number, row: any) => {
    const totalSales = totalSalesSalesFunction(row)
    if (totalSales > acc) {
      acc = totalSales
    }
    return acc
  }, 0)
  return rows.reduce((acc, row) => {
    const totalSales = totalSalesSalesFunction(row)
    let value: number
    try {
      value = (totalSales / max) * 100
    } catch (e) {
      value = 0
    }

    // round value to 2 decimal places
    value = Math.round(value * 100) / 100

    // value should not be below 0.1
    if (value < 0.1) {
      value = 0.1
    }
    acc[totalSales] = value
    return acc
  }, {} as Record<number, number>)
}
