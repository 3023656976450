<template>
  <div id="input-container" class="mr-20">
    <b-input
      v-model="formattedCurrencyValue"
      :disabled="isDisabled"
      :placeholder="placeholder"
      @blur="focusOut"
      @focus="focusIn"
    />
  </div>
</template>

<script>
import { getDecimalPlaces } from '@/lib/currencies'
import getSymbolFromCurrency from 'currency-symbol-map'
import Vue from 'vue'

export default Vue.extend({
  name: 'CurrencyInput',
  props: {
    isDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    value: {
      type: Number,
      required: false,
      default: undefined,
    },
    currency: {
      type: String,
      default: 'USD',
    },
  },
  data() {
    const decimalPlaces = getDecimalPlaces(this.currency)
    const pow = Math.pow(10, decimalPlaces)
    const value = (this.value / pow).toFixed(decimalPlaces)
    return {
      hasFocus: false,
      currencyValue: this.value !== undefined ? value : 0,
      formattedCurrencyValue:
        this.value !== undefined
          ? `${this.symbol} ` + value
          : `${this.symbol} ` + 0.0,
    }
  },
  computed: {
    inputValue() {
      return this.hasFocus ? this.currencyValue : this.formattedCurrencyValue
    },
    placeholder() {
      return `${this.symbol}0.00`
    },
    symbol() {
      return getSymbolFromCurrency(this.currency)
    },
  },
  mounted() {
    this.setFormattedCurrencyValue()
  },
  methods: {
    setFormattedCurrencyValue() {
      const decimalPlaces = getDecimalPlaces(this.currency)
      const pow = Math.pow(10, decimalPlaces)
      const rawCurrencyValue = this.formattedCurrencyValue.replace(
        /[^\d\.]/g,
        ''
      )
      this.currencyValue = Math.round(rawCurrencyValue * pow) / pow
      this.formattedCurrencyValue = `${this.symbol} ${this.currencyValue}`
    },
    focusOut() {
      const decimalPlaces = getDecimalPlaces(this.currency)
      const pow = Math.pow(10, decimalPlaces)
      this.hasFocus = false
      this.setFormattedCurrencyValue()
      this.$emit('input', Math.round(this.currencyValue * pow))
    },
    focusIn() {
      this.hasFocus = true
      this.formattedCurrencyValue = this.currencyValue.toString()
    },
  },
})
</script>
