








import Vue from 'vue'
import { CurrencyEnum } from '../../../../../shared/types/types'

export default Vue.extend({
  name: 'CurrencySelect',
  props: {
    value: {
      type: String,
      required: true,
    },
  },
  computed: {
    options() {
      return Object.values(CurrencyEnum)
    },
  },
})
