import flatten from 'just-flatten-it'
import { Filter } from './types'

export const mapFilterToElasticQuery = (
  orGroup: Filter
): Record<string, any> => {
  switch (orGroup.filter) {
    case 'ordersCancelled': {
      return {
        terms: {
          orderStatus:
            orGroup.values[0] === true ? ['canceled'] : ['open', 'archived'],
        },
      }
    }
    case 'ordersPaymentStatus': {
      return {
        terms: {
          paymentStatus: orGroup.values,
        },
      }
    }
    case 'ordersFulfillmentStatus': {
      return {
        terms: {
          fulfillmentStatus: orGroup.values,
        },
      }
    }
    case 'ordersOrderId': {
      return {
        terms: {
          orderId: orGroup.values,
        },
      }
    }
    case 'ordersDiscountName': {
      return {
        terms: {
          'discount.name': orGroup.values,
        },
      }
    }
    case 'ordersAccountIds': {
      return {
        terms: {
          'charge.paymentProvider.accountId': flatten(
            orGroup.values.map((v) => v.split(','))
          ),
        },
      }
    }
    case 'ordersCurrency': {
      return {
        terms: {
          'totalPrice.currency': flatten(
            orGroup.values.map((v) => v.split(','))
          ),
        },
      }
    }
    case 'billingsBillingCompany': {
      return {
        terms: {
          'customer.billingAddress.company': orGroup.values,
        },
      }
    }
    case 'billingsBillingCity': {
      return {
        terms: {
          'customer.billingAddress.city': orGroup.values,
        },
      }
    }
    case 'billingsBillingRegion': {
      return {
        terms: {
          'customer.billingAddress.divisionCode': orGroup.values,
        },
      }
    }
    case 'billingsBillingCountryRegion': {
      return {
        terms: {
          'customer.billingAddress.countryCode': orGroup.values,
        },
      }
    }
    case 'billingsBillingPostalCode': {
      return {
        terms: {
          'customer.billingAddress.addressCode': orGroup.values,
        },
      }
    }
    case 'shippingsShippingCompany': {
      return {
        terms: {
          'customer.shippingAddress.company': orGroup.values,
        },
      }
    }
    case 'shippingsShippingCity': {
      return {
        terms: {
          'customer.shippingAddress.city': orGroup.values,
        },
      }
    }
    case 'shippingsShippingRegion': {
      return {
        terms: {
          'customer.shippingAddress.divisionCode': orGroup.values,
        },
      }
    }
    case 'shippingsShippingCountryRegion': {
      return {
        terms: {
          'customer.shippingAddress.countryCode': orGroup.values,
        },
      }
    }
    case 'shippingsShippingPostalCode': {
      return {
        terms: {
          'customer.shippingAddress.addressCode': orGroup.values,
        },
      }
    }
    case 'customersCustomerEmail': {
      return {
        terms: {
          'customer.email': orGroup.values,
        },
      }
    }
    case 'customersCustomerId': {
      return {
        terms: {
          'customer.customerId': orGroup.values,
        },
      }
    }
    case 'customersCustomerName': {
      return {
        match: {
          'customer.fullName': {
            query: orGroup.values[0],
          },
        },
      }
    }
    case 'productsProductId': {
      return {
        nested: {
          path: 'lineItems',
          query: {
            terms: {
              'lineItems.productId': orGroup.values,
            },
          },
        },
      }
    }
    case 'productsPrice': {
      const rangeQuery: Record<string, any> = {
        range: {
          'lineItems.totalPrice.amount': {},
        },
      }
      const range = rangeQuery.range['lineItems.totalPrice.amount']
      switch (orGroup.operator) {
        case 'is': {
          range.lte = orGroup.values[0]
          range.gte = orGroup.values[0]
          break
        }
        case 'greater or equal': {
          range.gte = orGroup.values[0]
          break
        }
        case 'greater than': {
          range.gt = orGroup.values[0]
          break
        }
        case 'less or equal': {
          range.lte = orGroup.values[0]
          break
        }
        case 'less than': {
          range.lt = orGroup.values[0]
          break
        }
      }
      return {
        nested: {
          path: 'lineItems',
          query: rangeQuery,
        },
      }
    }
    case 'productsProductTitle': {
      return {
        nested: {
          path: 'lineItems',
          query: {
            terms: {
              'lineItems.productTitle.keyword': orGroup.values,
            },
          },
        },
      }
    }
    case 'productsVariantId': {
      return {
        nested: {
          path: 'lineItems',
          query: {
            terms: {
              'lineItems.variantId': orGroup.values,
            },
          },
        },
      }
    }
    case 'productsVariantSku': {
      return {
        nested: {
          path: 'lineItems',
          query: {
            terms: {
              'lineItems.sku': orGroup.values,
            },
          },
        },
      }
    }
    case 'productsVariantTitle': {
      return {
        nested: {
          path: 'lineItems',
          query: {
            terms: {
              'lineItems.variantTitle.keyword': orGroup.values,
            },
          },
        },
      }
    }
    default: {
      return {
        terms: {
          [orGroup.filter]: orGroup.values,
        },
      }
    }
  }
}
